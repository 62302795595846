<template>
<!-- 用户管理 -->
    <div>
        <div style="margin-bottom:15px">
            <el-input v-model.trim="listQuery.wechatName" placeholder="请输入微信名称" style="width:250px"></el-input>
            <el-button type="primary" style="margin-left:10px" @click="search">搜索</el-button>
        </div>
        
        <el-table :data="tableData" style="width: 100%">
            <el-table-column type="index" label="序号" width="100"></el-table-column>
            <el-table-column prop="wechatName" label="微信名称" width=""></el-table-column>
            <!-- <el-table-column prop="phone" label="手机号码" width="" show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="loginNum" label="登录次数" width="" show-overflow-tooltip></el-table-column>
            <el-table-column prop="updateTime" label="最后一次登录时间" width="" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="footer">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="listQuery.current"
                :page-size="listQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="listQuery.total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {message} from "../../api/index";
export default {
    data() {
        return {
            listQuery:{
                phone:"",
                current:1,
                size:10,
                total:0
            },
            tableData:[]
        }
    },
    mounted() {
        this.getTableData()
    },
    methods:{
        search() {
            this.listQuery.current = 1;
            this.getTableData()
        },
        handleCurrentChange(current) {
            this.listQuery.current = current;
            this.getTableData()
        },
        getTableData() {
            message({...this.listQuery}).then(res=>{
                if(res.code==1) {
                    this.tableData = res.data.records || [];
                    this.listQuery.total = res.data.total || 0
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}
</style>